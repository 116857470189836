import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import { login, logout } from '../redux';
import { connect } from 'react-redux';

class Login extends Component {
  render () {
    const { loggedIn, login, logout } = this.props;
    return (<div>
        {loggedIn ?
          <Button onClick={logout}>Log Out</Button>
          :
          <Button onClick={login}>Log In</Button>
        }</div>
    );
  }
}

const mapStateToProps = ({ authentication }) => {
  return {
    loggedIn: authentication.loggedIn,
    id: authentication.id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: () => {dispatch(login());},
    logout: () => {dispatch(logout());},
  };
};

Login = connect(mapStateToProps, mapDispatchToProps)(Login);

export { Login };