import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class PrivateRoute extends Component {

  render() {
    const { loggedIn, component: Component, ...rest } = this.props;
    return (
      <div>
        <Route {...rest} render={props => (
          loggedIn ? (
            <Component {...props}/>
          ) : (
            <Redirect to={{
              pathname: '/login',
              state: { from: props.location },
            }}/>
          )
        )}/>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }) => {
  return {
    loggedIn: authentication.loggedIn,
  };
};

PrivateRoute = connect(mapStateToProps)(PrivateRoute);

export { PrivateRoute };