import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Container, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import Snapshot from './Snapshot'
import { PrivateRoute } from './PrivateRoute'
import LoginPrompt from './LoginPrompt'
import Home from './Home'

import MainMenu from './MainMenu'
import ElectronicPayments from './ElectronicPayments'
import OneUser from './OneUser'
import SiriusXM from './SiriusXM'
import Vantage from './Vantage'
import PublishedMessages from './PublishedMessages'
import NewMessage from './EditMessage'
import GeneralStats from './GeneralStats'
import Everything from './Everything'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

class MainRouter extends Component {
  render () {
    const { loading } = this.props
    return (
      <Router>
        <div>
          <MainMenu/>
          <Dimmer active={loading} page>
            <Loader indeterminate>Loading Data</Loader>
          </Dimmer>
          <Container style={{ paddingTop: '7em' }}>
            <ToastContainer
              position="bottom-right"
              autoClose={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <Switch>
              <Route exact path="/login" component={LoginPrompt}/>
              <PrivateRoute exact path="/" component={Home}/>
              <PrivateRoute exact path="/analytics/snapshot" component={Snapshot}/>
              <PrivateRoute exact path="/analytics/electronicpayments" component={ElectronicPayments}/>
              <PrivateRoute exact path="/analytics/oneuserdetail" component={OneUser}/>
              <PrivateRoute exact path="/analytics/generalstats" component={GeneralStats}/>
              <PrivateRoute exact path="/analytics/everything" component={Everything}/>

              <PrivateRoute exact path="/thirdparty/siriusxm" component={SiriusXM}/>
              <PrivateRoute exact path="/thirdparty/vantage" component={Vantage}/>
              <PrivateRoute exact path="/messages/published" component={PublishedMessages}/>
              <PrivateRoute exact path="/messages/edit" component={NewMessage}/>
            </Switch>
          </Container>
        </div>
      </Router>
    )
  }
}

const mapStateToProps = ({ misc }) => {
  return {
    loading: misc.loading
  }
}

MainRouter = connect(mapStateToProps)(MainRouter)

export { MainRouter }
