import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Image, Menu, Dropdown, Container } from 'semantic-ui-react'
import { Login } from './Login'
import ServerSelection from './ServerSelection'
import { connect } from 'react-redux'
import { clearMessage } from '../redux'

class MainMenu extends Component {
  render () {
    const { loggedIn } = this.props
    return (
      <Menu fixed='top' inverted stackable>
        <Container>
          <Menu.Item as={Link} to="/" header>
            <Image size='tiny' src='/images/YellowRedTransparent.png' style={{ marginRight: '1.5em' }}/>
          </Menu.Item>

          { loggedIn ?
            <>
              <Dropdown item simple text='Analytics'>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/analytics/snapshot">Snapshot</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/analytics/electronicpayments">Electronic Payments</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/analytics/oneuserdetail">One User Detail</Dropdown.Item>
                  {/*<Dropdown.Item as={Link} to="/analytics/generalstats">General Stats</Dropdown.Item>*/}
                  <Dropdown.Item as={Link} to="/analytics/everything">Everything</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown item simple text='Third Party'>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/thirdparty/siriusxm">SiriusXM</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/thirdparty/vantage">Vantage</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown item simple text='Messaging'>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/messages/published">Published Messages</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.props.clearMessage()} as={Link} to="/messages/edit">New Message</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </>
            :
            null
          }
          <Menu.Menu position='right'>
            { loggedIn ?
              <Menu.Item>
                <ServerSelection/>
              </Menu.Item>
              :
              null
            }

            <Menu.Item>
              <Login/>
            </Menu.Item>
          </Menu.Menu>
        </Container>
      </Menu>
    )
  }
}

const mapStateToProps = ({ authentication }) => {
  return {
    loggedIn: authentication.loggedIn
  };
};

MainMenu = connect( mapStateToProps, { clearMessage })(MainMenu);

export default MainMenu;