import { getRequest } from '../components/common/api'

/* Types */

export const GET_SNAPSHOT_CATEGORIES = 'get_snapshot_categories';
export const GET_SNAPSHOT_DATA = 'get_snapshot_data'
export const GET_SNAPSHOT_USER_LIST = 'get_snapshot_user_list'
export const GET_PAST_30_DAYS = 'get_past_30_days'

/* Action */

export const getSnapshotCategories = () => {
    return async (dispatch) => {
        const result = await dispatch(getRequest('/analytics/2/pg/getcategories'))
        if (result) {
            dispatch({ type: GET_SNAPSHOT_CATEGORIES, payload: result })
        }
    };
};

export const getSnapshotData = (queryValue) => {
    return async (dispatch) => {
        const result = await dispatch(getRequest('/analytics/2/pg/' + queryValue.kind + '/' + queryValue.value))
        if (result) {
            dispatch({ type: GET_SNAPSHOT_DATA, payload: { tableData: result, searchedQuery: queryValue } })
        }
    };
}

export const getSnapshotUserList = (queryValue) => {
    return async (dispatch) => {
        const result = await dispatch(getRequest('/analytics/2/pg/userlist/' + queryValue.kind + '/' + queryValue.value))
        if (result) {
            dispatch({ type: GET_SNAPSHOT_USER_LIST, payload: { userList: result } })
        }
    }
}

export const getPast30Days = (company, category, type, index) => {
    return async (dispatch) => {
        const result = await dispatch(getRequest('/analytics/2/pg/past30/company/' + company + '/category/' + category + '/type/' + type))
        if (result) {
            dispatch({ type: GET_PAST_30_DAYS, payload: { data: result, index: index } })
        }
    }
}

/* Reducers */

const INITIAL_STATE = {
    categories: null,
    tableData: null,
    searchedQuery: null,
    userList: null,
    past30: null
};

export const snapshot = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_SNAPSHOT_CATEGORIES:
            return {
                ...state,
                categories: payload,
                tableData: null,
                userList: null,
                past30: null,
            };
        case GET_SNAPSHOT_DATA:
            return {
                ...state,
                tableData: payload.tableData,
                searchedQuery: payload.searchedQuery,
                userList: null,
                past30: null
            };
        case GET_SNAPSHOT_USER_LIST:
            return {
                ...state,
                userList: payload.userList
            }
        case GET_PAST_30_DAYS:
            return {
                ...state,
                past30: {
                    ...state.past30,
                    [payload.index]: payload.data
                }
            }
        default:
            return state;
    }
};