import { getRequest } from '../components/common/api'

/* Types */

const GET_VANTAGE_INQUIRIES = 'get_vantage_inquiries';

/* Action */

export const getVantageInquiries = (inputDate) => {
  return async (dispatch) => {
    let jsDate = new Date(inputDate)
    let year = jsDate.getFullYear()
    let day = jsDate.getDate()
    let month = jsDate.getMonth() + 1
    const result = await dispatch(getRequest('/analytics/2/pg/vantage/year/' + year + '/month/' +
    month + '/day/' + day))
    if (result) {
      dispatch({ type: GET_VANTAGE_INQUIRIES, payload: result })
    }
  };
};

/* Reducers */

const INITIAL_STATE = {
  tableData: null
};

export const vantage = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_VANTAGE_INQUIRIES:
      return {
        ...state,
        tableData: payload,
      };
    default:
      return state;
  }
};