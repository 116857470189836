import { getRequest, postRequest } from '../components/common/api'

/* Types */

const GET_SXM_USER_AGGREGATE_DATA = 'get_sxm_user_aggregate_data';
const GET_SXM_USER_FULL_DATA = 'get_sxm_user_full_data';
const GET_SXM_VIN_FULL_DATA = 'get_sxm_vin_full_data';
const GET_SXM_LOG_STATS = 'get_sxm_log_stats';
const GET_SXM_ENABLED_STATUS = 'get_sxm_enabled_status'

/* Action */

export const getSXMUserAggregateData = (frazerID) => {
  return async (dispatch) => {
    const result = await dispatch(getRequest('/siriusxm/realtime/analytics/useraggregate/' + frazerID))
    if (result) {
      dispatch({ type: GET_SXM_USER_AGGREGATE_DATA, payload: { frazerID: frazerID, tableData: result } })
    }
  };
};

export const getSXMVINFullData = (vin) => {
  return async (dispatch) => {
    const result = await dispatch(getRequest('/siriusxm/realtime/analytics/vin/' + vin))
    if (result) {
      dispatch({ type: GET_SXM_VIN_FULL_DATA, payload: { vin: vin, tableData: result } })
    }
  };
};

export const getSXMUserFullData = (frazerID) => {
  return async (dispatch) => {
    const result = await dispatch(getRequest('/siriusxm/realtime/analytics/user/' + frazerID))
    if (result) {
      dispatch({ type: GET_SXM_USER_FULL_DATA, payload: { frazerID: frazerID, tableData: result } })
    }
  };
};

export const getSXMLogStats = () => {
  return async (dispatch) => {
    const result = await dispatch(getRequest('/siriusxm/realtime/analytics/logstats'))
    if (result) {
      dispatch({ type: GET_SXM_LOG_STATS, payload: result })
    }
  };
};

export const getSXMEnabledStatus = (dateRange) => {
  return async (dispatch) => {
    const result = await dispatch(postRequest('/siriusxm/realtime/analytics/enabledstatus', JSON.stringify(dateRange)))
    console.log({ result: result })
    if (result) {
      dispatch({ type: GET_SXM_ENABLED_STATUS, payload: result })
    }
  }
}


/* Reducers */

const INITIAL_STATE = {
  frazerID: null,
  tableData: null,
  vin: null,
  aggregate: false,
  logStats: null,
  enabledStatus: null
};

export const siriusxm = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SXM_USER_AGGREGATE_DATA:
      return {
        ...state,
        tableData: payload.tableData,
        frazerID: payload.frazerID,
        vin: null,
        aggregate: true,
        logStats: null,
        enabledStatus: null
      };
    case GET_SXM_VIN_FULL_DATA:
      return {
        ...state,
        tableData: payload.tableData,
        frazerID: null,
        vin: payload.vin,
        aggregate: false,
        logStats: null,
        enabledStatus: null
      };
    case GET_SXM_USER_FULL_DATA:
      return {
        ...state,
        tableData: payload.tableData,
        frazerID: payload.frazerID,
        vin: null,
        aggregate: false,
        logStats: null,
        enabledStatus: null
      };
    case GET_SXM_LOG_STATS:
      return {
        ...state,
        tableData: null,
        frazerID: null,
        vin: null,
        aggregate: false,
        logStats: payload,
        enabledStatus: null
      };
    case GET_SXM_ENABLED_STATUS:
      return {
        ...state,
        tableData: null,
        frazerID: null,
        vin: null,
        aggregate: false,
        logStats: null,
        enabledStatus: payload
      }
    default:
      return state;
  }
};