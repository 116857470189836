import { combineReducers, applyMiddleware, createStore } from 'redux';
import { persistStore, autoRehydrate } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import { server } from './serverswitch';
import { authentication } from './authentication';
import { siriusxm } from './siriusxm';
import { snapshot } from './snapshot'
import { misc } from './misc'
import { oneuser } from './oneuser'
import { messages } from './messages'
import { vantage } from './vantage'
import { everything } from './everything'
import { epay } from './epay'

const appReducer = combineReducers({
  server,
  authentication,
  siriusxm,
  snapshot,
  misc,
  oneuser,
  messages,
  vantage,
  everything,
  epay
});

export const store = createStore(
  appReducer,
  {},
  composeWithDevTools(applyMiddleware(ReduxThunk), autoRehydrate()),
);

persistStore(store, {
  whitelist: ['authentication', 'server'],
});