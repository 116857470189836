import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Container, Divider } from 'semantic-ui-react';
import { getEverything } from '../redux'
import ReactTable from 'react-table'

class Everything extends Component {
  componentDidMount () {
    this.props.getEverything()
  }

  defineColumns = () => {
    let descColumns

    descColumns = [
      {
        columns: [
          {
            Header: "Category",
            accessor: "category"
          }, {
            Header: "Company",
            accessor: "company"
          }, {
            Header: "Type",
            accessor: "type"
          }, {
            Header: "Users",
            id: "users",
            accessor: (row) => Number.parseInt(row.users, 10) || 0,
            filterable: false,
            Cell: (data) => <div>{data.value.toLocaleString()}</div>,
            style: { "textAlign": "right" }
          }, {
            Header: "Events",
            id: "events",
            accessor: (row) => Number.parseInt(row.events, 10) || 0,
            filterable: false,
            Cell: (data) => <div>{data.value.toLocaleString()}</div>,
            style: { "textAlign": "right" },
          },
        ]
      }
    ]

    return descColumns
  }

  render () {
    const { tableData } = this.props
    return (
      <div>
        <Header as='h2' attached='top' block>
          Everything (Last 30 Days)
        </Header>
        <Segment>This listing returns every category, company, and type, with the distinct users and number of events
          for the last 30 days. Refresh the page to re-fetch values.

          Use the search boxes under Category, Company, and Type to filter results.
        </Segment>

        {tableData ?
          (
            <Container>
              <Divider horizontal>Results</Divider>
              <ReactTable
                filterable
                defaultFilterMethod={(filter, row) => {
                  return String(row[filter.id]).toLowerCase().indexOf(String(filter.value).toLowerCase()) > -1
                }}
                data={tableData}
                columns={this.defineColumns()}
                defaultPageSize={10}
                className="-striped -highlight"
                defaultSorted={[
                  {
                    id: "events",
                    desc: true
                  }
                ]}
              />
            </Container>
          ) :
          (
            <Container/>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = ({ everything }) => {
  return {
    tableData: everything.tableData
  }
}

Everything = connect(mapStateToProps, { getEverything })(Everything);

export default Everything;