import { SET_LOADING, CLEAR_LOADING } from '../../redux/misc'
import { refreshToken } from '../../redux'
import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

export const postRequest = (url, body) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADING })

    /* Check if token expired */
    if (jwtDecode(getState().authentication.token).exp * 1000 < Date.now()) {
      await dispatch(refreshToken())
    }

    const { server, authentication } = getState()

    const opts = {
      method: "POST",
      body: body,
      headers: {
        "Content-Type": "application/json",
        token: authentication.token
      }
    }

    const response = await fetch(`${server.url}${url}?XDEBUG_SESSION_START=xdebug.atom`, opts)

    const data = response.json()

    const error = isError(response, data)

    dispatch({ type: CLEAR_LOADING })

    if (error) {
      return null
    } else {
      return data
    }
  }
}

export const getRequest = (url) => {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADING })

    /* Check if token expired */
    if (jwtDecode(getState().authentication.token).exp * 1000 < Date.now()) {
      await dispatch(refreshToken())
    }

    const { server, authentication } = getState()

    const opts = {
      method: "GET",
      headers: {
        token: authentication.token
      }
    }

    const response = await fetch(`${server.url}${url}?XDEBUG_SESSION_START=xdebug.atom`, opts)

    console.log(response)

    const data = response.json()

    const error = isError(response, data)

    dispatch({ type: CLEAR_LOADING })

    if (error) {
      return null
    } else {
      return data
    }
  }
}

const isError = (response, data) => {
  if (response.status !== 200) {
    toast.error("Web request failed with error " + response.status + ": " +
      response.statusText + ". Please try again or contact developer.")
    return true
  } else if (data.error) {
    toast.error("Web request failed with error: " + data.error +
      " Please try again or contact developer.")
    return true
  }

  return false
}