import { getRequest } from '../components/common/api'

/* Types */

const GET_EPAY_DETAILS = 'get_epay_details';

/* Action */

export const getEPayDetails = () => {
  return async (dispatch) => {
    const result = await dispatch(getRequest('/analytics/2/pg/epayments'))
    if (result) {
      dispatch({ type: GET_EPAY_DETAILS, payload: result })
    }
  };
};

/* Reducers */

const INITIAL_STATE = {
  tableData: null
};

export const epay = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EPAY_DETAILS:
      return {
        ...state,
        tableData: payload,
      };
    default:
      return state;
  }
};