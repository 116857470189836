import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment } from 'semantic-ui-react';

class GeneralStats extends Component {
  render () {
    return (
      <div>
        <Header as='h4' attached='top' block>
          Yo!
        </Header>
        <Segment attached>You should only see this if logged in.</Segment>
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {}
};

GeneralStats = connect(mapStateToProps, {})(GeneralStats);

export default GeneralStats;