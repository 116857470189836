import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

const config = {
  apiKey: 'AIzaSyD596z6PGTyX1Pc-G2gwEo4ekhFveTzhUc',
  authDomain: 'frazerwebserviceofficeauth.firebaseapp.com',
  databaseURL: 'https://frazerwebserviceofficeauth.firebaseio.com',
  projectId: 'frazerwebserviceofficeauth',
  storageBucket: 'frazerwebserviceofficeauth.appspot.com',
  messagingSenderId: '447970062313',
};

firebase.initializeApp(config);

export const provider = new firebase.auth.OAuthProvider('microsoft.com');
provider.setCustomParameters({
  tenant: '515fce4f-ec7a-46f5-be34-13c81256a7d0',
  prompt: 'select_account',
});

export const auth = firebase.auth();
export const database = firebase.database();

export default firebase;
