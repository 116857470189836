import { auth, provider, database } from '../firebase';
import { toast } from 'react-toastify';

/* Extra Stuff */

let doAccountStuff = (authResult) => {
  return new Promise((resolve, reject) => {
    let validDomains = ['leadventure.com', 'frazer.biz', 'frazer.com'];
    if (
      !validDomains.includes(
        authResult.additionalUserInfo.profile.mail.split('@')[1]
      )
    ) {
      toast.error(
        'Invalid Microsoft account. You must have a valid leadventure.com, frazer.biz or frazer.com account.'
      );
      reject('Not a Frazer Account');
    }
    let userInfo = {};
    let dbString = '/users/' + authResult.additionalUserInfo.profile.id;
    database
      .ref(dbString)
      .once('value')
      .then((snapshot) => {
        if (snapshot.val()) {
          userInfo = snapshot.val();
        } else {
          userInfo = {
            name: authResult.additionalUserInfo.profile.displayName,
            email: authResult.additionalUserInfo.profile.mail,
            permissions: {
              messages: false,
              editmessage: false,
              oneuser: true,
              snapshot: true,
              siriusxm: true,
            },
          };
          database.ref(dbString).set(userInfo);
        }
        auth.currentUser.getIdToken(true).then((idToken) => {
          userInfo.token = idToken;
          userInfo.id = authResult.additionalUserInfo.profile.id;
          resolve(userInfo);
        });
      });
  });
};

/* Types */

const LOG_IN = 'log_in';
const LOG_OUT = 'log_out';
const REFRESH_TOKEN = 'refresh_token';

/* Actions */

export const login = () => {
  return (dispatch) => {
    auth.signInWithPopup(provider).then((result) => {
      doAccountStuff(result).then((userInfo) => {
        dispatch({
          type: LOG_IN,
          payload: userInfo,
        });
      });
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    auth.signOut().then(() => {
      dispatch({ type: LOG_OUT });
    });
  };
};

export const refreshToken = () => {
  return async (dispatch) => {
    return auth.currentUser.getIdToken(true).then((idToken) => {
      dispatch({
        type: REFRESH_TOKEN,
        payload: {
          token: idToken,
        },
      });
    });
  };
};

/* Reducers */

const INITIAL_STATE = {
  id: null,
  token: null,
  name: null,
  email: null,
  loggedIn: false,
  permissions: {},
};

export const authentication = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOG_IN:
      return {
        ...state,
        id: payload.id,
        token: payload.token,
        name: payload.name,
        email: payload.email,
        permissions: payload.permissions,
        loggedIn: true,
      };
    case LOG_OUT:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        token: payload.token,
      };
    default:
      return state;
  }
};
