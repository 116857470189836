import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Form, Button, Container, Divider } from 'semantic-ui-react';
import { getOneUserData } from '../redux'
import ReactTable from 'react-table'

class OneUser extends Component {
  constructor (props) {
    super(props);
    this.state = {
      frazerID: null
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  defineColumns = () => {
    let descColumns

    descColumns = [
      {
        columns: [
          {
            Header: "Data Type",
            accessor: "kind"
          }, {
            Header: "Date",
            id: "date",
            style: { "textAlign": "right" },
            accessor: (row) => new Date(row.date).getTime(),
            Cell: (data) => <div>{new Date(data.value).toLocaleString('en-US')}</div>
          }
        ]
      }
    ]

    return descColumns
  }

  render () {
    const { frazerID, tableData, getOneUserData } = this.props;
    return (
      <div>
        <Header as='h2' attached='top' block>
          Analytics - One User Detail
        </Header>
        <Segment>For the user number you enter, this function will show the most
          recent time a dealer accessed a given "Data Type" (thing tracked
          by analytics). If an expected data type is missing the dealer either
          hasn't used it, or they're running a version of Frazer where that
          data type was not yet being tracked.
          <strong> Note: To speed up searches, only results from the past 6 months
            are included.</strong>
        </Segment>

        <Form>
          <Form.Field>
            <Form.Input
              label='Frazer ID'
              placeholder='12345'
              name='frazerID'
              onChange={this.handleChange}/>
          </Form.Field>
          <Button type='submit' onClick={() => getOneUserData(this.state.frazerID)}>Submit</Button>
        </Form>
        {tableData ?
          (
            <Container>
              <Divider horizontal>Results</Divider>
              <Header size="small">Data for FrazerID: {frazerID}</Header>
              <ReactTable
                data={tableData}
                columns={this.defineColumns()}
                defaultPageSize={10}
                className="-striped -highlight"
                defaultSorted={[
                  {
                    id: "date",
                    desc: true
                  }
                ]}
              />
            </Container>
          ) :
          (
            <Container/>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = ({ oneuser }) => {
  return {
    tableData: oneuser.tableData,
    frazerID: oneuser.frazerID
  };
};

OneUser = connect(mapStateToProps, { getOneUserData })(OneUser);

export default OneUser;