import { getRequest } from '../components/common/api'

/* Types */

const GET_EVERYTHING = 'get_everything';

/* Action */

export const getEverything = () => {
  return async (dispatch) => {
    const result = await dispatch(getRequest('/analytics/2/pg/everything'))
    if (result) {
      dispatch({ type: GET_EVERYTHING, payload: result })
    }
  };
};

/* Reducers */

const INITIAL_STATE = {
  tableData: null
};

export const everything = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_EVERYTHING:
      return {
        ...state,
        tableData: payload,
      };
    default:
      return state;
  }
};