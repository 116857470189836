import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Divider, Header, Form, Container, Segment, Confirm } from 'semantic-ui-react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { editMessage, publishMessage } from '../redux'

class EditMessage extends Component {
  constructor (props) {
    super(props);

    this.state = {
      confirmSave: false,
      blockNavigation: true
    }
  }

  handleChange = (e, { name, value }) => {
    let fullMessage = {
      ...this.props.fullMessage,
      [name]: value
    }
    this.props.editMessage(fullMessage)
  }

  handleCheckbox = (e, { name }) => {
    let fullMessage = {
      ...this.props.fullMessage,
      [name]: !this.props.fullMessage[name]
    }
    this.props.editMessage(fullMessage)
  }

  onEditorStateChange = (editorState) => {
    let fullMessage = {
      ...this.props.fullMessage,
      content: editorState
    }
    this.props.editMessage(fullMessage)
  };

  getHeader = () => {
    const {
      messageID,
      whoEntered,
      modifiedDate
    } = this.props.fullMessage

    if (messageID) {
      return (
        <div>
          <Header as='h2' attached='top' block>
            Editing Message {messageID}
          </Header>
          <Segment attached>
            Last edited by {whoEntered} at {new Date(modifiedDate).toLocaleString('en-US')}
          </Segment>
          <Divider hidden/>
        </div>
      )
    } else {
      return (
        <div>
          <Header as='h2' attached='top' block>
            Add New Frazer Message
          </Header>
          <Divider hidden/>
        </div>
      )
    }
  }

  publishMessage = () => {
    this.props.publishMessage()
    this.props.history.push('/messages/published')
  }

  render () {
    const {
      title,
      updateVersion,
      forceMessage,
      updateForms,
      versions,
      dbType,
      userIDs,
      states,
      content,
    } = this.props.fullMessage

    return (
      <div>
        {this.getHeader()}
        <Container>
          <Form onSubmit={() => this.setState({ confirmSave: true, blockNavigation: false })}>

            <Form.Input
              required
              label='Title'
              value={title}
              placeholder='Enter a descriptive title/summary (required)'
              name='title'
              onChange={this.handleChange}
            />
            <Form.Input
              label='Frazer IDs'
              value={userIDs}
              placeholder='Enter Frazer user IDs (example: 2,4,700-704)'
              name='userIDs'
              onChange={this.handleChange}
            />
            <Form.Input
              label='States'
              value={states}
              placeholder='Enter dealer states (example: NY,NJ,GA)'
              name='states'
              onChange={this.handleChange}
            />
            <Form.Input
              required={Boolean(forceMessage)}
              label='Versions'
              value={versions}
              placeholder='Enter applicable version numbers with no leading 3.
              (example: 3555-3561,3212, required if message is Forced)'
              name='versions'
              onChange={this.handleChange}
            />

            <Header as='h4' attached='top' block>
              Database Type
            </Header>
            <Segment attached>
              <Form.Group inline>
                <Form.Radio
                  label='Both'
                  name='dbType'
                  value='Both'
                  checked={dbType === 'Both'}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  label='TPS'
                  name='dbType'
                  value='TPS'
                  checked={dbType === 'TPS'}
                  onChange={this.handleChange}
                />
                <Form.Radio
                  label='SQL'
                  name='dbType'
                  value='SQL'
                  checked={dbType === 'SQL'}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Segment>

            <Header as='h4' attached='top' block>
              Prompt the user to run an update
            </Header>
            <Segment attached>
              <Form.Group inline>
                <Form.Checkbox
                  label='Version'
                  checked={Boolean(updateVersion)}
                  name='updateVersion'
                  onChange={this.handleCheckbox}
                />
                <Form.Checkbox
                  label='Forms'
                  checked={Boolean(updateForms)}
                  name='updateForms'
                  onChange={this.handleCheckbox}
                />
              </Form.Group>
            </Segment>

            <Header as='h4' attached='top' block>
              Prompt the user to run an update
            </Header>
            <Segment attached>
              <Form.Group>
                <Form.Checkbox
                  label='Force (message will pop up every time the program opens until resolved, requires version number restriction)'
                  checked={Boolean(forceMessage)}
                  name='forceMessage'
                  onChange={this.handleCheckbox}
                />
              </Form.Group>
            </Segment>

            <Header as='h4' attached='top' block>
              Message to dealer
            </Header>
            <Segment attached>
              <Editor
                editorState={content}
                onEditorStateChange={this.onEditorStateChange}
              />
            </Segment>

            <Divider hidden/>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Form.Button type='submit' primary>Save and
                Publish</Form.Button>
              <Confirm
                open={this.state.confirmSave}
                header='Save and Publish Message'
                onCancel={() => this.setState({ confirmSave: false })}
                onConfirm={() =>
                  this.publishMessage()
                }
              />
            </div>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ messages }) => {
  return {
    fullMessage: messages.fullMessage
  }
};
EditMessage = connect(mapStateToProps, { editMessage, publishMessage })(EditMessage);

export default EditMessage;