/* Types */

const SET_LIVE_SERVER = "set_live_server";
const SET_TEST_SERVER = "set_test_server";
const SET_DEV_SERVER = "set_dev_server";

/* Actions */

export const setLiveServer = () => {
  return { type: SET_LIVE_SERVER };
};

export const setTestServer = () => {
  return { type: SET_TEST_SERVER };
};

export const setDevServer = () => {
  return { type: SET_DEV_SERVER };
};

/* Server Definitions */
// WARNING: You also need to make matching changes in ServerSelection.js

const liveServer = {
  name: "Live Server",
  url: "https://frazerwebservice.com/api",
};

const testServer = {
  name: "Test Server",
  url: "https://test.frazerwebservice.com/api",
};

const devServer = {
  name: "Dev Server (Monolith)",
  url: "https://legacywebsvc.development.frazercloud.com/messages",
};

/* Reducers */

export const server = (state = liveServer, action) => {
  const { type } = action;

  switch (type) {
    case SET_LIVE_SERVER:
      return {
        ...state,
        ...liveServer,
      };
    case SET_TEST_SERVER:
      return {
        ...state,
        ...testServer,
      };
    case SET_DEV_SERVER:
      return {
        ...state,
        ...devServer,
      };
    default:
      return state;
  }
};
