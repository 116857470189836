import React, { Component } from 'react';
import { MainRouter } from './components/MainRouter';

class App extends Component {
  render () {
    return (
      <div>
        <MainRouter/>
      </div>
    );
  }
}

export default App;
