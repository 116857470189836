import { getRequest } from '../components/common/api'

/* Types */

const GET_ONEUSER_DATA = 'get_oneuser_data';

/* Action */

export const getOneUserData = (frazerID) => {
  return async (dispatch) => {
    const result = await dispatch(getRequest('/analytics/2/pg/user/' + frazerID))
    if (result) {
      dispatch({ type: GET_ONEUSER_DATA, payload: { frazerID: frazerID, tableData: result } })
    }
  };
};

/* Reducers */

const INITIAL_STATE = {
  frazerID: null,
  tableData: null,
};

export const oneuser = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ONEUSER_DATA:
      return {
        ...state,
        tableData: payload.tableData,
        frazerID: payload.frazerID
      };
    default:
      return state;
  }
};