import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { setDevServer, setLiveServer, setTestServer } from "../redux";

class ServerSelection extends Component {
  render() {
    const { serverName, setLiveServer, setTestServer, setDevServer } =
      this.props;
    return (
      <Dropdown text={serverName}>
        <Dropdown.Menu>
          {/* WARNING: You also need to make matching changes in serverswitch.js */}
          <Dropdown.Item onClick={() => setLiveServer()} text="Live Server" />
          <Dropdown.Item onClick={() => setTestServer()} text="Test Server" />
          <Dropdown.Item
            onClick={() => setDevServer()}
            text="Dev Server (Monolith)"
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ server }) => {
  return {
    serverName: server.name,
  };
};

ServerSelection = connect(mapStateToProps, {
  setDevServer,
  setLiveServer,
  setTestServer,
})(ServerSelection);

export default ServerSelection;
