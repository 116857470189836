import {getRequest, postRequest} from '../components/common/api'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html';
import { ContentState, EditorState, convertToRaw } from 'draft-js'

/* Types */

const GET_MESSAGE_LISTING = 'get_message_listing';
const GET_MESSAGE = 'get_message'
const EDIT_MESSAGE = 'edit_message'
const CLEAR_MESSAGE = 'clear_message'

/* Action */

export const getMessageListing = () => {
  return async (dispatch) => {
    const result = await dispatch(getRequest('/messagebackend/pg/listing'))
    if (result) {
      dispatch({ type: GET_MESSAGE_LISTING, payload: result })
    }
  };
};

export const getMessage = (messageID) => {
  return async (dispatch) => {
    const result = await dispatch(getRequest('/messagebackend/pg/full/' + messageID))
    if (result) {
      let messageContent = result.content
      const contentBlock = htmlToDraft(messageContent)

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        result.content = EditorState.createWithContent(contentState);
      }
      dispatch({ type: GET_MESSAGE, payload: result })
    }
  };
}

export const editMessage = (fullMessage) => {
  return { type: EDIT_MESSAGE, payload: fullMessage }
}

export const clearMessage = () => {
  return { type: CLEAR_MESSAGE }
}

export const publishMessage = () => {
  return async (dispatch, getState) => {
    const fullMessage = getState().messages.fullMessage
    if (typeof fullMessage.content !== 'string') {
      const rawContent = convertToRaw(fullMessage.content.getCurrentContent())
      fullMessage.content = draftToHtml(rawContent)
    }
    fullMessage.whoEntered = getState().authentication.email

    const result = await dispatch(postRequest('/messagebackend/pg/full/' + fullMessage.messageID, JSON.stringify(fullMessage)))
    console.log({result: result})
    if (result) {
      dispatch({ type: CLEAR_MESSAGE })
    }
  }
}

/* Reducers */

const INITIAL_STATE = {
  tableData: null,
  fullMessage: {
    messageID: '',
    title: "",
    updateVersion: false,
    forceMessage: false,
    updateForms: false,
    sensitiveMessage: false,
    versions: "",
    dbType: "Both",
    userIDs: "",
    whoEntered: "",
    states: "",
    content: EditorState.createEmpty(),
    modifiedDate: ""
  }
};

export const messages = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_MESSAGE_LISTING:
      return {
        ...state,
        tableData: payload
      };
    case GET_MESSAGE:
      return {
        ...state,
        fullMessage: payload
      };
    case EDIT_MESSAGE:
      return {
        ...state,
        fullMessage: payload
      }
    case CLEAR_MESSAGE:
      return {
        ...state,
        fullMessage: INITIAL_STATE.fullMessage
      }
    default:
      return state;
  }
};