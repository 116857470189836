import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Container, Divider } from 'semantic-ui-react';
import { getEPayDetails } from '../redux'
import ReactTable from 'react-table'

class ElectronicPayments extends Component {
  componentDidMount () {
    this.props.getEPayDetails()
  }

  defineColumns = () => {
    /* This is really disgusting and needs to be done better probably */
    let today = new Date()
    let lastMonth = new Date()
    lastMonth.setMonth(today.getMonth() - 1)

    /* getMonth in Javascript is 0 based for some reason
    * Can't modify the line above, otherwise years would get messed up */
    let thisMonthNumber = today.getMonth() + 1
    let lastMonthNumber = lastMonth.getMonth() + 1

    let descColumns = [
      {
        Header: "",
        columns: [
          {
            Header: "Company",
            accessor: "company"
          }, {
            Header: "Type",
            accessor: "type"
          }
        ]
      }, {
        Header: "This Month (" + thisMonthNumber + "/" + today.getFullYear() + ")",
        columns: [
          {
            Header: "Sum",
            id: "thismonthsum",
            accessor: (row) => Number.parseFloat(row.thismonthsum) || 0,
            style: { "textAlign": "right" },
            Cell: (data) => <div>{data.value.toLocaleString()}</div>
          }, {
            Header: "Average",
            id: "thismonthaverage",
            accessor: (row) => Number.parseFloat(row.thismonthaverage) || 0,
            style: { "textAlign": "right" },
            Cell: (data) => <div>{data.value.toLocaleString()}</div>
          }
        ]
      }, {
        Header: "Last Month (" + lastMonthNumber + "/" + lastMonth.getFullYear() + ")",
        columns: [
          {
            Header: "Sum",
            id: "lastmonthsum",
            accessor: (row) => Number.parseFloat(row.lastmonthsum) || 0,
            style: { "textAlign": "right" },
            Cell: (data) => <div>{data.value.toLocaleString()}</div>
          }, {
            Header: "Average",
            id: "lastmonthaverage",
            accessor: (row) => Number.parseFloat(row.lastmonthaverage) || 0,
            style: { "textAlign": "right" },
            Cell: (data) => <div>{data.value.toLocaleString()}</div>
          }
        ]
      }, {
        Header: "Last 30 Days",
        columns: [
          {
            Header: "Sum",
            id: "thirtydayssum",
            accessor: (row) => Number.parseFloat(row.thirtydayssum) || 0,
            style: { "textAlign": "right" },
            Cell: (data) => <div>{data.value.toLocaleString()}</div>
          }, {
            Header: "Average",
            id: "thirtydaysaverage",
            accessor: (row) => Number.parseFloat(row.thirtydaysaverage) || 0,
            style: { "textAlign": "right" },
            Cell: (data) => <div>{data.value.toLocaleString()}</div>
          }
        ]
      }
    ]


    return descColumns
  }

  render () {
    const { tableData } = this.props
    return (
      <div>
        <Header as='h2' attached='top' block>
          Electronic Payments
        </Header>
        <Segment>This listing shows details about the number of transactions (counts) and
          dollar amount totals (sums) logged with electronic payments analytics requests. Use
          the "Snapshot" if searching for number of users.
        </Segment>

        {tableData ?
          (
            <Container>
              <Divider horizontal>Results</Divider>
              <ReactTable
                data={tableData}
                columns={this.defineColumns()}
                defaultPageSize={10}
                className="-striped -highlight"
                defaultSorted={[
                  {
                    id: "events",
                    desc: true
                  }
                ]}
              />
            </Container>
          ) :
          (
            <Container/>
          )
        }
      </div>
    );
  }
}

const
  mapStateToProps = ({ epay }) => {
    return {
      tableData: epay.tableData
    }
  }

ElectronicPayments = connect(mapStateToProps, { getEPayDetails })(ElectronicPayments);

export default ElectronicPayments;