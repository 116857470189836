import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Form, Button, Container, Divider } from 'semantic-ui-react';
import { getSXMUserAggregateData, getSXMVINFullData, getSXMUserFullData, getSXMLogStats, getSXMEnabledStatus } from '../redux'
import ReactTable from 'react-table'

import {
  DateInput,
} from 'semantic-ui-calendar-react';

class SiriusXM extends Component {
  constructor (props) {
    super(props);

    let today = new Date()
    let lastMonth = new Date()
    lastMonth.setMonth(today.getMonth() - 1)

    this.state = {
      frazerID: null,
      vin: null,
      // First day of last month
      enabledStateStart: new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1).toLocaleDateString('en-US'),
      // 0th day of this month = last day of last month
      enabledStateEnd: new Date(today.getFullYear(), today.getMonth(), 0).toLocaleDateString('en-US')
    }
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  defineUserColumns = () => {
    let descColumns

    descColumns = [
      {
        columns: [
          {
            Header: "Month",
            accessor: "month"
          }, {
            Header: "Type",
            accessor: "type",
          }, {
            Header: "Events",
            id: "events",
            accessor: (row) => Number.parseInt(row.events, 10),
            style: { "textAlign": "right" },
          }
        ]
      }
    ]

    return descColumns
  }

  defineFullColumns = () => {
    let descColumns

    descColumns = [
      {
        columns: [
          {
            Header: "Frazer ID",
            id: "userID",
            accessor: (row) => Number.parseInt(row.userID, 10)
          }, {
            Header: "Date",
            id: "date",
            style: { "textAlign": "right" },
            accessor: (row) => Number.parseInt(row.date.sec, 10) * 1000,
            Cell: (data) => <div>{new Date(data.value).toLocaleString('en-US')}</div>
          }, {
            Header: "VIN",
            accessor: "data.vin"
          }, {
            Header: "Method",
            accessor: "method",
          }, {
            Header: "Result",
            style: { "textAlign": "right" },
            accessor: "response.resultCode"
          }
        ]
      }
    ]

    return descColumns
  }

  generateTable = () => {
    const { tableData, frazerID, vin, aggregate } = this.props

    if (tableData) {
      if (frazerID && aggregate) {
        return (
          <Container>
            <Divider horizontal>Results</Divider>
            <Header size="small">Data for FrazerID: {frazerID}</Header>
            <ReactTable
              data={tableData}
              columns={this.defineUserColumns()}
              defaultPageSize={10}
              className="-striped -highlight"
              SubComponent={() => {}}
            />
          </Container>
        )
      } else if (!aggregate) {

        const header = () => {
          if (vin) {
            return (
              <Header size="small">Data for VIN: {vin}</Header>
            )
          } else {
            return (
              <Header size="small">Data for FrazerID: {frazerID}</Header>
            )
          }
        }

        return (
          <Container>
            <Divider horizontal>Results</Divider>
            {header()}
            <ReactTable
              data={tableData}
              columns={this.defineFullColumns()}
              defaultPageSize={10}
              className="-striped -highlight"
              SubComponent={row => {
                return (
                  <Container>
                    <Header as='h5' attached='top' block>
                      Data
                    </Header>
                    <Segment attached>
                      <code>
                        {JSON.stringify(row.original.data, null, '\t')}
                      </code>
                    </Segment>
                    <Header as='h5' attached='top' block>
                      Response
                    </Header>
                    <Segment attached>
                      <code>
                        {JSON.stringify(row.original.response, null, '\t')}
                      </code>
                    </Segment>
                  </Container>
                )
              }}
            />
          </Container>
        )
      } else {
        return <Container/>
      }
    } else {
      return <Container/>
    }
  }

  logStatsTable = () => {
    const { logStats } = this.props

    const descColumns = [
      {
        columns: [
          {
            Header: "Month",
            id: "date",
            accessor: (row) => new Date(row.date).getTime(),
            Cell: (data) => {
              const date = new Date(data.value)
              const month = date.getUTCMonth() + 1
              const year = date.getFullYear()
              return (
                <div>{month + "/" + year}</div>
              )
            }
          }, {
            Header: "createAccountTrialUsed (Sales)",
            id: "createAccountTrialUsed",
            accessor: (row) => (
              row.data.find(
                (element) => (element.method === "createAccountTrialUsed")
              ).number
            )
          }, {
            Header: "updateDeviceDemo (Inventory)",
            id: "updateDeviceDemo",
            accessor: (row) => (
              row.data.find(
                (element) => (element.method === "updateDeviceDemo")
              ).number
            )
          }, {
            Header: "updateAccountDeactivateTrialUsed",
            id: "updateAccountDeactivateTrialUsed",
            accessor: (row) => (
              row.data.find(
                (element) => (element.method === "updateAccountDeactivateTrialUsed")
              ).number
            )
          }, {
            Header: "refreshDeviceOverSat",
            id: "refreshDeviceOverSat",
            accessor: (row) => (
              row.data.find(
                (element) => (element.method === "refreshDeviceOverSat")
              ).number
            )
          }
        ]
      }
    ]

    if (logStats) {
      return (
        <Container>
          <Divider horizontal>Results</Divider>
          <ReactTable
            data={logStats}
            columns={descColumns}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </Container>
      )
    } else {
      return <Container/>
    }
  }

  enabledStatus = () => {
    let dateRange = {
      start: this.state.enabledStateStart,
      end: this.state.enabledStateEnd
    }
    this.props.getSXMEnabledStatus(dateRange)
  }

  enabledStatusTable = () => {
    const { enabledStatus } = this.props

    const descColumns = [
      {
        columns: [
          {
            Header: "Frazer ID",
            accessor: "frazerID"
          }, {
            Header: "Date Disabled",
            id: "dateDisabled",
            style: { "textAlign": "right" },
            accessor: (row) => new Date(row.dateDisabled).getTime(),
            Cell: (data) => <div>{new Date(data.value).toLocaleString('en-US')}</div>
          }
        ]
      }
    ]

    if (enabledStatus) {
      return (
        <Container>
          <Divider horizontal>Results</Divider>
          <ReactTable
            data={enabledStatus}
            columns={descColumns}
            defaultPageSize={10}
            className="-striped -highlight"
          />
        </Container>
      )
    } else {
      return <Container/>
    }
  }


  render () {
    const { getSXMUserAggregateData, getSXMVINFullData, getSXMUserFullData, getSXMLogStats } = this.props;

    return (
      <div>
        <Header as='h2' attached='top' block>
          SiriusXM Real Time
        </Header>
        <Divider hidden/>
        <Form>
          <Header as='h4' attached='top' block>
            Aggregate Data for User
          </Header>
          <Segment attached>
            Looks up aggregate event counts for a given Frazer ID for the past
            few months. This data this pulled from the "general" analytics data database.
            <Divider hidden/>
            <Form.Field>
              <Form.Input
                label='Frazer ID'
                placeholder='12345'
                name='frazerID'
                onChange={this.handleChange}/>
            </Form.Field>
            <Button type='submit' onClick={() => getSXMUserAggregateData(this.state.frazerID)}>Submit</Button>
          </Segment>

          <Header as='h4' attached='top' block>
            Submissions Search
          </Header>
          <Segment attached>
            Looks up logged event data for a given Frazer ID or VIN.
            <Divider hidden/>
            <Form.Field>
              <Form.Input
                label='Frazer ID'
                placeholder='12345'
                name='frazerID'
                onChange={this.handleChange}/>
            </Form.Field>
            <Button type='submit' onClick={() => getSXMUserFullData(this.state.frazerID)}>Submit</Button>
            <Divider hidden/>
            <Form.Field>
              <Form.Input
                label='VIN'
                placeholder='1FTFW1EV1AFB51561'
                name='vin'
                onChange={this.handleChange}/>
            </Form.Field>
            <Button type='submit' onClick={() => getSXMVINFullData(this.state.vin)}>Submit</Button>
          </Segment>
        </Form>
        {this.generateTable()}

        <Header as='h4' attached='top' block>
          Successful Real Time Submissions
        </Header>
        <Segment attached>
          Look up the number of distinct Frazer IDs that submitted successful requests
          to SiriusXM for the past 6 months, for each submission type.
          <Divider hidden/>
          <Button type='submit' onClick={() => getSXMLogStats()}>Get Data</Button>
        </Segment>
        {this.logStatsTable()}

        <Header as='h4' attached='top' block>
          Enabled Status
        </Header>
        <Segment attached>
          For the given date range, return Frazer IDs for dealers who have marked the SiriusXM integration
          as disabled in the Frazer software.
          <Divider hidden/>
          <Form>
            <Form.Field>
              <label>Start Date</label>
              <DateInput
                dateFormat={'MM/DD/YYYY'}
                name="enabledStateStart"
                value={this.state.enabledStateStart}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>End Date</label>
              <DateInput
                dateFormat={'MM/DD/YYYY'}
                name="enabledStateEnd"
                value={this.state.enabledStateEnd}
                onChange={this.handleChange}
              />
            </Form.Field>
            <Button type='submit' onClick={() => this.enabledStatus()}>Submit</Button>
          </Form>
        </Segment>
        {this.enabledStatusTable()}
      </div>
    );
  }
}

const mapStateToProps = ({ siriusxm }) => {
  return {
    tableData: siriusxm.tableData,
    frazerID: siriusxm.frazerID,
    vin: siriusxm.vin,
    aggregate: siriusxm.aggregate,
    logStats: siriusxm.logStats,
    enabledStatus: siriusxm.enabledStatus
  }
};

SiriusXM = connect(mapStateToProps, {
  getSXMUserAggregateData,
  getSXMVINFullData,
  getSXMUserFullData,
  getSXMLogStats,
  getSXMEnabledStatus
})(SiriusXM);

export default SiriusXM;