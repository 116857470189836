import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment } from 'semantic-ui-react';

class Home extends Component {
  render () {
    return (
      <div>
        <Header as='h4' attached='top' block>
          Welcome {this.props.name}!
        </Header>
        <Segment attached>Click an item from the menu above to get started.</Segment>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication }) => {
  return {
    name: authentication.name,
  };
};

Home = connect(mapStateToProps)(Home);

export default Home;