/* Types */

export const SET_LOADING = 'set_loading'
export const CLEAR_LOADING = 'clear_loading'

/* Action */

/* Reducers */

const INITIAL_STATE = {
  loading: false,
}

export const misc = (state = INITIAL_STATE, action) => {
  const { type } = action

  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        loading: true
      }
    case CLEAR_LOADING:
      return {
        ...state,
        loading: false
      }
    default:
      return state;
  }
}