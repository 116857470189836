import React, { Component } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';


class LoginPrompt extends Component {
  render () {
    const { loggedIn, location } = this.props;
    return (
      loggedIn ?
        (
          <Redirect to={location.state?.from || "/"}/>
        ) :
        (
          <div>
            <Header as='h4' attached='top' block>
              Not logged in
            </Header>
            <Segment attached>Please log in to continue.</Segment>
          </div>
        )
    );
  }
}

const mapStateToProps = ({ authentication }) => {
  return {
    loggedIn: authentication.loggedIn,
  };
};

LoginPrompt = withRouter(connect(mapStateToProps)(LoginPrompt));

export default LoginPrompt;