import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Divider, Container, Button } from 'semantic-ui-react';
import ReactTable from 'react-table'
import { getMessageListing, getMessage, clearMessage } from '../redux'

class PublishedMessages extends Component {
  constructor (props) {
    super(props);
    this.state = {};
  }

  componentDidMount () {
    this.props.getMessageListing()
  }

  defineColumns = () => {
    let descColumns

    descColumns = [
      {
        columns: [
          {
            Header: "ID",
            id: "messageID",
            accessor: (row) => Number.parseInt(row.messageID, 10)
          }, {
            Header: "Title",
            accessor: "title"
          }, {
            Header: "Date Added/Modified",
            id: "modifiedDate",
            style: { "textAlign": "right" },
            accessor: (row) => new Date(row.modifiedDate).getTime(),
            Cell: (data) => <div>{new Date(data.value).toLocaleString('en-US')}</div>
          }
        ]
      }
    ]

    return descColumns
  }

  newMessage = () => {
    this.props.clearMessage()
    this.props.history.push('/messages/edit')
  }

  render () {
    const { tableData, getMessage, history } = this.props;
    return (
      <div>
        <Header as='h2' attached='top' block>
          Published Messages
        </Header>
        <Divider hidden/>

        {tableData ?
          (
            <Container>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button primary onClick={() => this.newMessage()}>
                  Add New Message
                </Button>
              </div>
              <Divider hidden/>
              <ReactTable
                data={tableData}
                columns={this.defineColumns()}
                defaultPageSize={10}
                className="-striped -highlight"
                defaultSorted={[
                  {
                    id: "messageID",
                    desc: true
                  }
                ]}
                getTdProps={(state, rowInfo) => {
                  return {
                    onClick: (e, handleOriginal) => {
                      getMessage(rowInfo.original.messageID);
                      history.push('/messages/edit')
                      if (handleOriginal) {
                        handleOriginal();
                      }
                    }
                  };
                }}
              />

            </Container>
          ) :
          (
            <Container/>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = ({ messages }) => {
  // Check for an error
  if (messages.tableData && typeof messages.tableData.error == "string") {
    // As this is an internal tool, we'll just console.log the error for now for troubleshooting
    console.log(messages.tableData.error)
    // If we have an error, just return null for tableData so it doesn't crash the app
    return {
      tableData: null
    }
  }
  // No error, proceed normally
  return {
    tableData: messages.tableData
  }
};

PublishedMessages = connect(mapStateToProps, { getMessageListing, getMessage, clearMessage })(PublishedMessages);

export default PublishedMessages;