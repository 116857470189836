import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Header, Segment, Form, Button, Container, Divider } from 'semantic-ui-react';
import { getVantageInquiries } from '../redux'

import {
  DateInput,
} from 'semantic-ui-calendar-react';

import ReactTable from 'react-table'

class Vantage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // Date 7 days ago. Kinda ugly but works.
      date: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US')
    }
  }

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  }

  defineColumns = () => {
    let descColumns

    descColumns = [
      {
        columns: [
          {
            Header: "Frazer ID",
            accessor: "frazerid"
          }, {
            Header: "Date",
            id: "date",
            style: { "textAlign": "right" },
            accessor: (row) => new Date(row.date).getTime(),
            Cell: (data) => <div>{new Date(data.value).toLocaleString('en-US')}</div>
          }
        ]
      }
    ]

    return descColumns
  }

  render () {
    const { tableData, getVantageInquiries } = this.props
    return (
      <div>
        <Header as='h2' attached='top' block>
          Vantage Inquiries
        </Header>
        <Segment>For the date entered, this function will return a list of user numbers
          and the time they made the Vantage Inquiry analytics request for the following week.
          For example, entering 6/1/2017 will give you all users submitting inquiry requests
          from 6/1/2017 at midnight to 6/8/2017 at midnight.
        </Segment>

        <Form>
          <Form.Field>
            <label>Date</label>
            <DateInput
              dateFormat={'MM/DD/YYYY'}
              name="date"
              value={this.state.date}
              onChange={this.handleChange}
            />
          </Form.Field>
          <Button type='submit' onClick={() => getVantageInquiries(this.state.date)}>Submit</Button>
        </Form>

        {tableData ?
          (
            <Container>
              <Divider horizontal>Results</Divider>
              <Header size="small">Data for date range beginning {this.state.date}</Header>
              <ReactTable
                data={tableData}
                columns={this.defineColumns()}
                defaultPageSize={10}
                className="-striped -highlight"
                defaultSorted={[
                  {
                    id: "date",
                    desc: true
                  }
                ]}
              />
            </Container>
          ) :
          (
            <Container/>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = ({ vantage }) => {
  return {
    tableData: vantage.tableData
  }
}

Vantage = connect(mapStateToProps, { getVantageInquiries })(Vantage);

export default Vantage;