import React, { Component } from 'react';
import _ from 'lodash'
import { connect } from 'react-redux';
import { Header, Segment, Container, Dropdown, Divider, Button } from 'semantic-ui-react';
import { getSnapshotCategories, getSnapshotData, getSnapshotUserList, getPast30Days } from '../redux'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

class Snapshot extends Component {
    constructor (props) {
        super(props);

        this.state = {
            searchValue: '',
            suggestions: [],
            searchLoading: false,
            expanded: {}
        };
    }

    componentDidMount () {
        this.props.getSnapshotCategories()
    }

    defineColumns = (kind) => {
        let descColumns

        switch (kind) {
            case 'category':
                descColumns = [
                    {
                        Header: "",
                        columns: [
                            {
                                Header: "Company",
                                accessor: "company"
                            },
                            {
                                Header: "Type",
                                accessor: "type",
                                Footer: (
                                    <div style={{ "textAlign": "right" }}>Totals:</div>
                                )
                            }
                        ]
                    }
                ]
                break
            case 'company':
                descColumns = [
                    {
                        Header: "",
                        columns: [
                            {
                                Header: "Category",
                                accessor: "category"
                            },
                            {
                                Header: "Type",
                                accessor: "type",
                                Footer: (
                                    <div style={{ "textAlign": "right" }}>Totals:</div>
                                )
                            }
                        ]
                    }
                ]
                break
            case 'type':
                descColumns = [
                    {
                        Header: "",
                        columns: [
                            {
                                Header: "Company",
                                accessor: "company"
                            },
                            {
                                Header: "Category",
                                accessor: "category",
                                Footer: (
                                    <div style={{ "textAlign": "right" }}>Totals:</div>
                                )
                            }
                        ]
                    }
                ]
                break
            default:
                break
        }

        /* This is really disgusting and needs to be done better probably */
        let today = new Date()
        let lastMonth = new Date()
        lastMonth.setMonth(today.getMonth() - 1)

        /* getMonth in Javascript is 0 based for some reason
        * Can't modify the line above, otherwise years would get messed up */
        let thisMonthNumber = today.getMonth() + 1
        let lastMonthNumber = lastMonth.getMonth() + 1

        let numberColumns = [
            {
                Header: "This Month (" + thisMonthNumber + "/" + today.getFullYear() + ")",
                columns: [
                    {
                        Header: "Users",
                        id: "thismonthuser",
                        accessor: (row) => Number.parseInt(row.thismonthuser, 10) || 0,
                        style: { "textAlign": "right" },
                        Cell: (data) => <div>{data.value.toLocaleString()}</div>
                    },
                    {
                        Header: "Events",
                        id: "thismonthevent",
                        accessor: (row) => Number.parseInt(row.thismonthevent, 10) || 0,
                        style: { "textAlign": "right" },
                        Cell: (data) => <div>{data.value.toLocaleString()}</div>,
                        Footer: (
                            <div>{_.sum(_.map(this.props.tableData, (row) => Number.parseInt(row.thismonthevent, 10) || 0)).toLocaleString()} </div>
                        )
                    }
                ]
            }, {
                Header: "Last Month (" + lastMonthNumber + "/" + lastMonth.getFullYear() + ")",
                columns: [
                    {
                        Header: "Users",
                        id: "lastmonthuser",
                        accessor: (row) => Number.parseInt(row.lastmonthuser, 10) || 0,
                        style: { "textAlign": "right" },
                        Cell: (data) => <div>{data.value.toLocaleString()}</div>
                    },
                    {
                        Header: "Events",
                        id: "lastmonthevent",
                        accessor: (row) => Number.parseInt(row.lastmonthevent, 10) || 0,
                        style: { "textAlign": "right" },
                        Cell: (data) => <div>{data.value.toLocaleString()}</div>,
                        Footer: (
                            <div>{_.sum(_.map(this.props.tableData, (row) => Number.parseInt(row.lastmonthevent, 10) || 0)).toLocaleString()} </div>
                        )
                    }
                ]
            }, {
                Header: "Last 30 Days",
                columns: [
                    {
                        Header: "Users",
                        id: "thirtydaysuser",
                        accessor: (row) => Number.parseInt(row.thirtydaysuser, 10) || 0,
                        style: { "textAlign": "right" },
                        Cell: (data) => <div>{data.value.toLocaleString()}</div>
                    },
                    {
                        Header: "Events",
                        id: "thirtydaysevent",
                        accessor: (row) => Number.parseInt(row.thirtydaysevent, 10) || 0,
                        style: { "textAlign": "right" },
                        Cell: (data) => <div>{data.value.toLocaleString()}</div>,
                        Footer: (
                            <div>{_.sum(_.map(this.props.tableData, (row) => Number.parseInt(row.thirtydaysevent, 10) || 0)).toLocaleString()} </div>
                        )
                    }
                ]
            }
        ]

        return descColumns.concat(numberColumns)
    }

    resetSearch = () => {
        this.setState({
            searchValue: '',
            suggestions: [],
            searchLoading: false,
            expanded: {}
        })
    }

    onSearchChange = (event) => {
        this.setState({ searchValue: event.target.value, searchLoading: true })

        setTimeout(() => {
            if (this.state.searchValue.length < 1) return this.resetSearch()

            const re = new RegExp(_.escapeRegExp(this.state.searchValue), 'i')
            const isMatch = result => re.test(result.value)

            /* The map to add title/key are so the search function doesn't yell at me */
            const filteredSuggestions = this.props.categories.filter(isMatch).map(
                (item, index) => ({
                    ...item,
                    title: item.value,
                    key: index
                })
            )

            this.setState({ suggestions: filteredSuggestions, searchLoading: false })
        }, 300)
    }

    subColumns = () => ([
        {
            Header: 'Activity by Day',
            columns: [
                {
                    Header: 'Date',
                    accessor: 'the_date'
                },
                {
                    Header: 'Users',
                    accessor: 'users'
                },
                {
                    Header: 'Events',
                    accessor: 'events'
                }
            ]
        }
    ])

    snapshotSubcomponent = (row, searchedQuery, past30) => {
        let category, company, type
        switch (searchedQuery.kind) {
            case 'category':
                company = row.original.company
                category = searchedQuery.value
                type = row.original.type
                break
            case 'company':
                company = searchedQuery.value
                category = row.original.category
                type = row.original.type
                break
            case 'type':
                company = row.original.company
                category = row.original.category
                type = searchedQuery.value
                break
            default:
                break
        }

        let data = past30 && past30[row.index] ?
            past30[row.index] :
            null

        return (
            <Container style={{ padding: "20px" }}>
                {
                    data ?
                        (
                            <ReactTable
                                data={data}
                                columns={this.subColumns()}
                            />
                        ) :
                        (<div/>)
                }
                <br/>
                <Button onClick={() => this.props.getPast30Days(company, category, type, row.index)}>Daily Activity for
                    Last 30
                    Days</Button>
            </Container>
        )
    }

    onResultSelect = (event, { value }) => {
        let suggestionAtIndex = this.state.suggestions[value]
        let queryValue = {
            kind: suggestionAtIndex.kind,
            value: suggestionAtIndex.value
        }
        this.props.getSnapshotData(queryValue)
        this.resetSearch()
    }

    render () {
        const { tableData, searchedQuery, getSnapshotUserList, userList, past30 } = this.props;
        const { searchValue, suggestions, searchLoading, expanded } = this.state

        /* This is a pain in the ass. Options prop on Dropdown only accepts objects of the types indicated
              so this maps options to an array Dropdown likes, and onChange returns the index
              which can then be used to get back the stuff we actually want from the original suggestions
              array */

        return (
            <div>
                <Header as='h2' attached='top' block>
                    Analytics Snapshot
                </Header>
                <Segment>
                    Enter a search term in the box below to look up aggregate data for this month,
                    last month, and the past 30 days.
                </Segment>
                <Dropdown
                    loading={searchLoading}
                    search
                    fluid
                    scrolling
                    selection
                    placeholder={'Begin typing search term here to populate list'}
                    onSearchChange={_.debounce(this.onSearchChange, 500, { leading: true })}
                    value={searchValue}

                    options={suggestions.map((element, index) => {
                        return {
                            key: index,
                            label: element.kind,
                            text: element.value,
                            value: index,
                        }
                    })}
                    selectOnNavigation={false}
                    onChange={this.onResultSelect}
                    selectOnBlur={false}
                    onBlur={this.resetSearch}
                />

                {tableData ?
                    (
                        <Container>
                            <Divider horizontal>Results</Divider>
                            <Header size="small">Results for {searchedQuery.kind}: {searchedQuery.value}</Header>
                            <ReactTable
                                data={tableData}
                                columns={this.defineColumns(searchedQuery.kind)}
                                defaultPageSize={10}
                                className="-striped -highlight"
                                defaultSorted={[
                                    {
                                        id: "thirtydaysevent",
                                        desc: true
                                    }
                                ]}
                                expanded={expanded}
                                onExpandedChange={(newExpanded, index) => {
                                    this.setState({
                                        ...this.state,
                                        expanded: newExpanded
                                    })
                                }}
                                SubComponent={row => (
                                        this.snapshotSubcomponent(row, searchedQuery, past30)
                                    )
                                }
                            />
                            <Divider hidden/>
                            <Button onClick={() => getSnapshotUserList(searchedQuery)}>Get "{searchedQuery.value}" users
                                for last 30
                                days</Button>
                            {userList ?
                                (
                                    <Container>
                                        <Divider hidden/>
                                        <Segment>{userList.join(", ")}</Segment>
                                    </Container>
                                ) :
                                (
                                    <Container/>
                                )

                            }
                        </Container>
                    ) :
                    (
                        <Container/>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = ({ snapshot }) => {
    return {
        categories: snapshot.categories,
        tableData: snapshot.tableData,
        searchedQuery: snapshot.searchedQuery,
        userList: snapshot.userList,
        past30: snapshot.past30
    }
};


Snapshot = connect(mapStateToProps, {
    getSnapshotCategories,
    getSnapshotData,
    getSnapshotUserList,
    getPast30Days
})(Snapshot);

export default Snapshot;